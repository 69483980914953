<template>
    <div class="ad-container flex-shrink-0">
        <span class="adv-claim">Advertisement</span>
        <div :class="advClassesToAttach">
            <div v-if="isLazyLoaded" class="gptslot infinite-ad"></div>
            <div v-else class="gptslot" :data-adunitid="adUnit"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdUnit',
    props: {
        'ad-unit': {},
        'ad-type': {
            required: true,
        },
        'is-lazy-loaded': {
            default: false,
        }
    },
    computed: {
        advClassesToAttach() {
            // Aggiungiamo ad solo se l'unità è in pagina
            const baseClass = this.isLazyLoaded ? '' : 'ad';

            const mobileClasses = this.isMobile ? 'ad-mobile' : '';

            return [baseClass, mobileClasses, this.adType].join(' ');
        }
    },
    data() {
        return {
            isMobile: this.$store.state.app.isMobile
        }
    }
}
</script>

<style scoped>

.adv-claim {
    font-size: 9px;
    text-transform: uppercase;
    color: rgba(255,255,255,.2);
    background-clip: padding-box;
    position: absolute;
    top: -15px;
    z-index: 10;
    padding: 0 10px;
}
.adv-claim::before {
    content: '';
    position: absolute;
    inset: 0;
    background: inherit; /* Riprende lo sfondo gradiente */
    z-index: -1;
    mask: linear-gradient(#fff 0 0); /* Maschera il bordo */
    -webkit-mask: linear-gradient(#fff 0 0); /* Compatibilità Safari */
}

.ad {
    border: 1px solid rgba(255,255,255,.2);
}
.ad > div {
    transform: scale(90%);
}
.ad-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ad {
    position: relative;
    z-index: 0;
}

.ad::after {
    /* @apply leading-none font-semibold text-gray-500 text-sm flex items-center justify-center; */
    color: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    font-size: 9px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.ad-lazy-load {
    margin: auto;
    text-align: center;
}

.ad-rectangle {
    width: 300px;
    height: 300px;
    overflow: hidden;
    align-content: center;
}

.ad-masthead {
    width: 728px;
    height: 100px;
    position: relative;
    z-index: 0;
}

.ad-masthead.ad-mobile {
    width: 100%;
    height: 250px;
}


</style>
