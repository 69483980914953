<template>
    <div v-if="$store.getters.withFooter"
         class="w-full border-t border-current-theme fixed inset-x-0 bottom-0 z-10 bg-black opacity-90">
        <div class="mx-auto px-2 xs:px-4 sm:px-0 sm:max-w-sm ">

            <div class="grid grid-cols-3 gap-2 sm:gap-4 p-2 sm:py-2 footer">

                <a href="https://clockbeats.com/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center"
                >
                    <img src="../../assets/img/logo/logo-clockbeats-verticale-bianco.png" class="hidden sm:block pl-6 py-1 mx-auto object-contain">
                    <img src="../../assets/img/logo/logo-clockbeats-orizzontale-bianco.png" class="sm:hidden xs:py-2 pr-4 h-full mx-auto block object-contain" >
                </a>

                <a href="https://www.nuovoimaie.it/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center"
                >
                    <img src="../../assets/img/logo/logo-footer-imaie-vert.png" class="hidden sm:block h-full mx-auto block object-contain">
                    <img src="../../assets/img/logo/logo-footer-imaie.png" class="sm:hidden py-0 pl-0 sm:py-1 xs:pl-4 mx-auto block object-contain">
                </a>

                <a href="https://www.siae.it/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center"
                ><img src="../../assets/img/logo/siae_logo.png" class="py-2 xs:py-1 sm:py-1 sm:pl-2 h-full mx-auto block object-contain" ></a>

                <!--
                <a href="https://www.ticketsms.it/" target="_blank" rel="noopener noreferrer" class="flex items-center justify-center"
                >
                    <img src="../../assets/img/logo/ticketsms-logo-emblema-bianco.png" class="hidden sm:block h-full mx-auto block object-contain">
                    <img src="../../assets/img/logo/ticketsms-logo-emblema-bianco.png" class="sm:hidden h-full mx-auto block object-contain">
                </a>


                <a href="https://www.ticketone.it/" target="_blank" rel="noopener noreferrer"
                ><img class="block w-24 mb-1 -mt-1 opacity-90 hover:opacity-100 transition-all duration-200" src="../../assets/img/tone_logo.png"></a>

                <a href="https://www.tiscali.it/" target="_blank" rel="noopener noreferrer"
                ><img class="block w-24 opacity-90 hover:opacity-100 transition-all duration-200" src="../../assets/img/logo-tiscali.png"></a>
                -->
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>
