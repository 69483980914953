import Vue from 'vue';
import Router from 'vue-router';
import Home from '../components/pages/Home.vue';
import Poll from '../components/pages/Poll.vue';
import NotFound from '../components/pages/NotFound.vue';
import JuryLoginPage from '../components/pages/JuryLoginPage.vue';
import { getValidPolls } from '@/shared/common';
import parse from 'date-fns/parse';
import getTime from 'date-fns/get_time';
import { store } from './../store/index';

const RSVP = () => import(/* webpackChunkName: "rsvp" */ '../components/pages/Rsvp.vue');
const Disclaimer = () => import(/* webpackChunkName: "disclaimer" */ '../components/pages/Disclaimer.vue');

Vue.use(Router);

const routes = [];

const winnerScreenVisibleFrom = process.env.VUE_APP_SHOW_WINNERS_FROM
  ? getTime(parse(process.env.VUE_APP_SHOW_WINNERS_FROM))
  : null;

if (winnerScreenVisibleFrom && winnerScreenVisibleFrom < store.state.app.currentTimestamp) {
  routes.push({
    name: 'home',
    path: '/',
    component: () => import(/* webpackChunkName: "winners" */ '../components/pages/Winners'),
    meta: {
      title: 'Rockol Awards ' + process.env.VUE_APP_AWARDS_YEAR + ' - i vincitori!',
    },
  });
} else {
  const homeRoute = {
    name: 'home',
    path: '/',
    component: Home,
    meta: {
      title: 'Rockol Awards ' + process.env.VUE_APP_AWARDS_YEAR + ' - vota!',
    },
  };

  routes.push(homeRoute);

  routes.push({
      name: 'rsvp',
      path: '/rsvp',
      component: RSVP,
  });

  routes.push({
    name: 'disclaimer',
    path: '/disclaimer',
    component: Disclaimer,
  });

  const pollDefinition = getValidPolls(process.env.VUE_APP_POLLS_SECTIONS || '');

  // We push the routes from the poll definitions
  if (store.state.app.showCandidates) {
    Object.keys(pollDefinition).forEach(function (index) {
      const item = pollDefinition[index];
      const route = {
        name: index,
        path: item.path,
        component: Poll,
        props: {
          pollName: index,
          candidateType: item.candidateType,
        },
        meta: {
          title: item.title + ' - Rockol Awards',
        },
      };

      routes.push(route);

      // Aggiungiamo anche la rotta con lo slug
      const detailRoute = Object.assign({}, route);
      detailRoute.path = item.path + '/:slug';
      routes.push(detailRoute);

    });
  }

  routes.push({
    name: 'juryLogin',
    path: '/accesso-giuria',
    component: JuryLoginPage,
    meta: {
      title: 'Accesso dedicato alla giuria - Rockol Awards',
    },
  });
}

if ((process.env.VUE_APP_ENABLE_ADMIN_PANELS || 'no') === 'yes') {
  const SocialAssets = () =>
    import(/* webpackChunkName: "admin" */ './../components/admin/social/SocialAssets');

  routes.push({
    path: '/social-assets',
    name: 'social-assets',
    component: SocialAssets,
  });

}

routes.push({
  path: '*',
  name: 'not-found',
  component: NotFound,
});

const routerOptions = {
  mode: 'history',
  routes,
};

// In case of production environment we set the base path to the vpath
routerOptions.base = process.env.VUE_APP_RELATIVE_PATH + '/';

const router = new Router(routerOptions);

/*
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const user = firebase.auth().currentUser
    if (requiresAuth && !user) {
        next('/signin')
    }
    next()
})
*/

let currentBodyClass;
router.beforeEach((to, from, next) => {
  // Title set
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  // Body class Set
  const body = document.getElementsByTagName('body')[0];
  if (currentBodyClass) {
    body.classList.remove(currentBodyClass);
  }

  if (to.name) {
    body.classList.add(to.name);
    currentBodyClass = to.name;
  }

  if (from.name !== null) {
    // We're on a second navigation we set additional tracking
    const path = (router.options.base + to.path).replace(/\/\//g, '/');
    // Tracking
    if (typeof ga !== 'undefined') {
      ga('send', 'pageview', path);
      ga('additionalTracker.send', 'pageview', path);
    }
    if (typeof wt !== 'undefined') {
      wt.sendinfo();
    }
  }

  next();
});

export default router;
