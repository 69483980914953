<template>
    <modal-view @close-panel="closePanel" :open="showPanel" v-if="showPanel">
        <div class="px-2 max-w-xl flex flex-col space-y-4 items-center justify-center">

            <div class="flex items-start space-x-4">
                <svg class="vote-completed w-12 h-12" width="150" height="150" xmlns="http://www.w3.org/2000/svg"
                     version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 510 510"
                     style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g>
                            <g id="check-circle">
                                <path
                                    d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M204,382.5L76.5,255l35.7-35.7    l91.8,91.8l193.8-193.8l35.7,35.7L204,382.5z"
                                    fill="#FFFFFF"/>
                            </g>
                        </g>
                    </svg>
                <h1 class="text-2xl font-semibold _uppercase _tracking-wide">
                    Grazie per aver votato <strong>{{ candidateTitle }}</strong> come <strong>
                    {{ $store.getters.currentPollTitle }}</strong>.<br>
                </h1>

            </div>
            <div v-if="shareUrl" class="  max-w-xl flex flex-col space-y-4 items-center justify-center">

                <div class="space-y-2">
                    <p>
                        Fai sapere a tutti che hai votato e invitali a scegliere il loro artista preferito!
                        <span v-if="socialSharingEnabled">
                            <br><br>Ecco cosa puoi fare:
                        </span>
                    </p>
                    <div class="text-sm flex items-start space-x-4" v-if="instagramStory">
                        <div class="mt-1">
                            <!-- Instagram -->
                            <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0  132.004 132"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <linearGradient id="ig_b">
                                        <stop offset="0" stop-color="#3771c8"/>
                                        <stop stop-color="#3771c8" offset=".128"/>
                                        <stop offset="1" stop-color="#60f" stop-opacity="0"/>
                                    </linearGradient>
                                    <linearGradient id="ig_a">
                                        <stop offset="0" stop-color="#fd5"/>
                                        <stop offset=".1" stop-color="#fd5"/>
                                        <stop offset=".5" stop-color="#ff543e"/>
                                        <stop offset="1" stop-color="#c837ab"/>
                                    </linearGradient>
                                    <radialGradient id="ig_c" cx="158.429" cy="578.088" r="65" xlink:href="#ig_a"
                                                    gradientUnits="userSpaceOnUse"
                                                    gradientTransform="matrix(0 -1.98198 1.8439 0 -1031.402 454.004)"
                                                    fx="158.429" fy="578.088"/>
                                    <radialGradient id="ig_d" cx="147.694" cy="473.455" r="65" xlink:href="#ig_b"
                                                    gradientUnits="userSpaceOnUse"
                                                    gradientTransform="matrix(.17394 .86872 -3.5818 .71718 1648.348 -458.493)"
                                                    fx="147.694" fy="473.455"/>
                                </defs>
                                <path fill="url(#ig_c)"
                                      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                      transform="translate(1.004 1)"/>
                                <path fill="url(#ig_d)"
                                      d="M65.03 0C37.888 0 29.95.028 28.407.156c-5.57.463-9.036 1.34-12.812 3.22-2.91 1.445-5.205 3.12-7.47 5.468C4 13.126 1.5 18.394.595 24.656c-.44 3.04-.568 3.66-.594 19.188-.01 5.176 0 11.988 0 21.125 0 27.12.03 35.05.16 36.59.45 5.42 1.3 8.83 3.1 12.56 3.44 7.14 10.01 12.5 17.75 14.5 2.68.69 5.64 1.07 9.44 1.25 1.61.07 18.02.12 34.44.12 16.42 0 32.84-.02 34.41-.1 4.4-.207 6.955-.55 9.78-1.28 7.79-2.01 14.24-7.29 17.75-14.53 1.765-3.64 2.66-7.18 3.065-12.317.088-1.12.125-18.977.125-36.81 0-17.836-.04-35.66-.128-36.78-.41-5.22-1.305-8.73-3.127-12.44-1.495-3.037-3.155-5.305-5.565-7.624C116.9 4 111.64 1.5 105.372.596 102.335.157 101.73.027 86.19 0H65.03z"
                                      transform="translate(1.004 1)"/>
                                <path fill="#fff"
                                      d="M66.004 18c-13.036 0-14.672.057-19.792.29-5.11.234-8.598 1.043-11.65 2.23-3.157 1.226-5.835 2.866-8.503 5.535-2.67 2.668-4.31 5.346-5.54 8.502-1.19 3.053-2 6.542-2.23 11.65C18.06 51.327 18 52.964 18 66s.058 14.667.29 19.787c.235 5.11 1.044 8.598 2.23 11.65 1.227 3.157 2.867 5.835 5.536 8.503 2.667 2.67 5.345 4.314 8.5 5.54 3.054 1.187 6.543 1.996 11.652 2.23 5.12.233 6.755.29 19.79.29 13.037 0 14.668-.057 19.788-.29 5.11-.234 8.602-1.043 11.656-2.23 3.156-1.226 5.83-2.87 8.497-5.54 2.67-2.668 4.31-5.346 5.54-8.502 1.18-3.053 1.99-6.542 2.23-11.65.23-5.12.29-6.752.29-19.788 0-13.036-.06-14.672-.29-19.792-.24-5.11-1.05-8.598-2.23-11.65-1.23-3.157-2.87-5.835-5.54-8.503-2.67-2.67-5.34-4.31-8.5-5.535-3.06-1.187-6.55-1.996-11.66-2.23-5.12-.233-6.75-.29-19.79-.29zm-4.306 8.65c1.278-.002 2.704 0 4.306 0 12.816 0 14.335.046 19.396.276 4.68.214 7.22.996 8.912 1.653 2.24.87 3.837 1.91 5.516 3.59 1.68 1.68 2.72 3.28 3.592 5.52.657 1.69 1.44 4.23 1.653 8.91.23 5.06.28 6.58.28 19.39s-.05 14.33-.28 19.39c-.214 4.68-.996 7.22-1.653 8.91-.87 2.24-1.912 3.835-3.592 5.514-1.68 1.68-3.275 2.72-5.516 3.59-1.69.66-4.232 1.44-8.912 1.654-5.06.23-6.58.28-19.396.28-12.817 0-14.336-.05-19.396-.28-4.68-.216-7.22-.998-8.913-1.655-2.24-.87-3.84-1.91-5.52-3.59-1.68-1.68-2.72-3.276-3.592-5.517-.657-1.69-1.44-4.23-1.653-8.91-.23-5.06-.276-6.58-.276-19.398s.046-14.33.276-19.39c.214-4.68.996-7.22 1.653-8.912.87-2.24 1.912-3.84 3.592-5.52 1.68-1.68 3.28-2.72 5.52-3.592 1.692-.66 4.233-1.44 8.913-1.655 4.428-.2 6.144-.26 15.09-.27zm29.928 7.97c-3.18 0-5.76 2.577-5.76 5.758 0 3.18 2.58 5.76 5.76 5.76 3.18 0 5.76-2.58 5.76-5.76 0-3.18-2.58-5.76-5.76-5.76zm-25.622 6.73c-13.613 0-24.65 11.037-24.65 24.65 0 13.613 11.037 24.645 24.65 24.645C79.617 90.645 90.65 79.613 90.65 66S79.616 41.35 66.003 41.35zm0 8.65c8.836 0 16 7.163 16 16 0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16z"/>
                            </svg>
                        </div>
                        <div class="opacity-80">
                            <a :href="instagramStory" class="underline font-semibold" target="_blank"
                               rel="noopener noreferrer">
                                Scarica la story</a> e condividila su <strong>Instagram</strong>
                            taggando @Rockol.it, potresti essere condiviso sul nostro profilo ufficiale!
                        </div>
                    </div>
                    <div class="text-sm flex items-start space-x-4" v-if="facebookFeedPhoto">
                        <div class="mt-1">
                            <!-- Facebook -->
                            <svg class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"
                                 style="enable-background:new 0 0 40 40" xml:space="preserve"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="-277.375" y1="406.602" x2="-277.375" y2="407.573" gradientTransform="matrix(40 0 0 -39.7778 11115.001 16212.334)"><stop offset="0" style="stop-color:#0062e0"/><stop
                                offset="1" style="stop-color:#19afff"/></linearGradient>
                                <path
                                    d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z"
                                    style="fill:url(#a)"/>
                                <path
                                    d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z"
                                    style="fill:#fff"/></svg>
                        </div>
                        <div class="opacity-80">
                            <div v-if="facebookFeedPhoto">
                                <a :href="facebookFeedPhoto" class="underline font-semibold" target="_blank"
                                   rel="noopener noreferrer">
                                    Scarica l'immagine</a> per creare un post su <strong>Facebook</strong>.
                            </div>
                        </div>
                    </div>
                    <div class="text-sm flex items-start space-x-4" v-if="socialTag">
                        <div class="mt-1">
                            <!-- Twitter -->
                            <svg class="w-8 h-8" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                                    fill="white"/>
                            </svg>

                        </div>
                        <div class="opacity-80">
                            Su <strong>X</strong> usa l’hashtag <span
                            class="font-semibold">#{{ socialTag }}</span>,
                            tagga <span class="font-semibold">@rockolpoprock</span> e spiega perché hai scelto di votare

                            <span class="font-semibold">{{ candidateTitle }}</span>, potresti essere ritwittato
                            dal nostro account <strong>X</strong> ufficiale!
                        </div>
                    </div>
                </div>

                <p class="text-xl text-center">
                    Spargi la voce direttamente<br> cliccando i pulsanti qui sotto:
                </p>

                <share-component
                    :url="shareUrl"
                    :title="shareTitle"
                    :description="shareDescription"
                    :isMobileRequest="isMobileRequest"
                    :additionalHashTags="additionalHashTags"
                    :showLink="false"></share-component>
            </div>

            <div v-if="nrVotesToDo > 0" class="flex flex-col space-y-4 items-center justify-center">
                <p class="text-xl text-center">
                    Ti
                    <span v-if="nrVotesToDo == 1">rimane</span>
                    <span v-else>rimangono</span>
                    ancora

                    <strong>
                        {{ nrVotesToDo }}

                        <span v-if="nrVotesToDo == 1">voto</span>

                        <span v-else>voti</span>
                    </strong>

                    da esprimere:
                </p>

                <router-link
                    v-for="(singlePoll,idx) in voteList" :key="idx"
                    :to="{ name: singlePoll.routeName}"
                    class="underline">
                    {{ singlePoll.title }}
                </router-link>
            </div>
        </div>
    </modal-view>
</template>

<script>

import ShareComponent from '../../shared/ShareComponent.vue';
import {
    ARTIST_CANDIDATE_TYPE,
    CREATOR_CANDIDATE_TYPE,
    pollDefinition,
    RECORD_CANDIDATE_TYPE,
    VIDEO_CANDIDATE_TYPE,
} from '../../../shared/common';
import ModalView from '../../page-components/ModalView';

export default {
    created: function () {
        const vm = this;
        window.addEventListener('keyup', function (e) {
            if (e.key === "Escape") {
                vm.$store.commit('closeVotePanel');
            }
        });
    },
    components: {
        ModalView,
        'share-component': ShareComponent,
    },
    watch: {
        showPanel(show) {
            if (show) {
                this.$root.$emit('show-vote-complete-panel');
            }
        }
    },
    data() {
        return {
            iconsHeight: 120
        }
    },
    computed: {
        socialSharingEnabled() {
            return this.instagramStory || this.facebookFeedPhoto || this.socialTag;
        },
        instagramStory() {
            const path = (this.votedCandidate.social_assets.find(x => x.social_network === 'ig-story-path') || {}).value || null;
            if (path && process.env.VUE_APP_SOCIAL_ASSETS_BASE_URL) {
                return process.env.VUE_APP_SOCIAL_ASSETS_BASE_URL + path;
            }
            return null;
        },
        facebookFeedPhoto() {
            const path = (this.votedCandidate.social_assets.find(x => x.social_network === 'fb-feed') || {}).value || null;
            if (path && process.env.VUE_APP_SOCIAL_ASSETS_BASE_URL) {
                return process.env.VUE_APP_SOCIAL_ASSETS_BASE_URL + path;
            }
            return null;
        },
        socialTag() {
            return (this.votedCandidate.social_assets.find(x => x.social_network === 'social-tag') || {}).value || null;
        },
        additionalHashTags() {
            return this.socialTag ? [this.socialTag] : [];
        },
        votedCandidate() {
            const pollCode = this.$store.getters.currentPollCode;
            if (this.$store.getters.userVoteState.votes[pollCode]) {
                return this.$store.getters.userVoteState.votes[pollCode].data;
            }
            return null;
        },
        isMobileRequest() {
            return this.$store.state.app.isMobile;
        },
        nrVotesToDo() {
            return this.$store.getters.pollsToVoteList.length;
        },
        voteList() {
            return this.$store.getters.pollsToVoteList;
        },
        showPanel() {
            return this.$store.getters.votePanel.show;
        },
        shareUrl() {
            const candidateData = this.votedCandidate;
            if (candidateData) {
                const basePath = this.$store.state.app.host + this.$store.state.app.basePath;
                const vPath = pollDefinition[this.$store.getters.currentPollCode]['path'];
                const sectionPath = basePath + vPath;

                return candidateData.slug
                    ? sectionPath + '/' + candidateData.slug
                    : sectionPath + "?cid=" + candidateData['cid'];
            }
            return null;
        },
        candidateTitle() {
            const candidateData = this.votedCandidate;
            if (!candidateData) {
                return null;
            }
            switch (candidateData.type) {
                case ARTIST_CANDIDATE_TYPE:
                    return candidateData.artist_data.artist_name;
                case RECORD_CANDIDATE_TYPE:
                    return candidateData.artist_name + " - " + candidateData.release_title;
                case VIDEO_CANDIDATE_TYPE:
                    return candidateData.title;
                case CREATOR_CANDIDATE_TYPE:
                    return candidateData.creator_name;
                default:
                    return null;
            }
        },
        shareTitle() {
            return `Rockol Awards ${process.env.VUE_APP_AWARDS_YEAR}:
                Ho votato '${this.candidateTitle}'
                per la categoria '${this.$store.getters.currentPollTitle}'`
                .replace(/\n/g, " ")
                .replace(/\s+/g, " ");
        },
        shareDescription() {
            return null;
        },
        shareQuote() {
            return null;
        },
    },
    methods: {
        closePanel() {
            this.$store.commit('closeVotePanel');
        }
    }
}
</script>

<style lang="scss">
.detail__fade {
    color: fade_out(white, .5);
}
</style>
